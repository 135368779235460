
.List {
	border-bottom: solid 1px lightGray;
	&:last-child {
		border-bottom: none;
	}
	&:hover{
		cursor: pointer;
		background-color: lightGray;
	}
}