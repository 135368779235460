
.dividerContainer {

  width: 100%;
  // background-color: #123321;

  div:nth-of-type(1) {
    background-color: var(--main-color);
  }
  div:nth-of-type(2) {
    background-color: var(--medium-color);
  }
  div:nth-of-type(3) {
    background-color: var(--secondary-color);
  }
  div:nth-of-type(4) {
    background-color: var(--light-color);
  }

}