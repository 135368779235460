:root {
  --main-color: #a79581;
  --secondary-color: #ebdebe;
  --light-color: #f5ebdf;
  --medium-color: #caaf91;
}

:root,
[data-amplify-theme],
[data-amplify-authenticator] {
  --amplify-colors-border-focus: #a79581;
  --amplify-components-button-primary-background-color: #a79581;
  --amplify-components-button-primary-hover-background-color: #756a5fd4;
  --amplify-components-button-link-color: #a79581;
  --amplify-components-button-primary-focus-background-color: #a79581;
  --amplify-components-button-primary-active-background-color: #a79581;
  --amplify-components-button-link-hover-background-color: #f5ebdf53;
  --amplify-components-button-link-hover-color: black;
  --amplify-components-authenticator-router-border-color: none;
  --amplify-components-authenticator-router-border-style: none;
}

/***** reset *****/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  user-select: none;
}

/***** MAIN *****/

h2 {
  font-size: 1.2rem;
  color: rgb(99, 98, 98);
}

main {
  width: 100%;
  background-color: rgba(167, 149, 129, 0.1);
}

/* MODAL CONTAINER */
.fade {
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  background-color: rgba(245, 235, 223, 0.8);
  text-align: center;
}

/***** LOGIN *****/
.loginPage {
  width: 100vw;
  height: 100vh;
  padding-top: 5rem;
  background-color: rgba(167, 149, 129, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.loginLogo {
  width: 100px;
  height: 100px;
}

/***** HEADER SECTION *****/
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.logoContainer {
  align-self: center;
  justify-self: center;
}

.logoContainer img {
  width: 12rem;
}

header .textButton {
  color: var(--main-color);
}

.textButton:hover {
  color: rgb(99, 98, 98);
  background-color: inherit;
}

.logButtonGroup {
  order: 3;
}

/***** media min-width 1200px *****/

@media screen and (min-width: 1200px) {

  h2 {
    font-size: 1.8rem;
  }

  .loginPage {
    padding-top: 10rem;
  }

  header {
    padding: 1rem 2rem;
  }

  .logoContainer img {
    width: 16rem;
  }

  .input {
    max-width: 48%;
    flex: 1 1 auto;
  }

  .buttonGroup {
    justify-content: flex-start;
  }

  .buttonGroup {
    justify-content: center;
    gap: 1rem;
  }

  .logButtonGroup {
    flex: 2;
    justify-content: flex-end;
    align-items: center;
    order: 2;
  }
}

@media screen and (min-width: 1600px) {

  .buttonGroup .Button,
  .buttonGroup .backButton,
  .buttonGroup .deleteButton {
    font-size: 0.9rem;
    padding: 0.5rem 2rem;
  }
}