.formHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .buttonContainer {
    display: flex;
    gap: 1rem;
  }
}

.sectionContent {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
}


.customerSelectSection {
  width: 100%;
  background-color: var(--main-color);
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .sectionContent {
    flex-wrap: nowrap;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
  }
  
}

.customerSection, .logSection  {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sectionContent {
    flex-direction: column;
    padding: 0 2rem;
    padding-top: 1rem;
    background-color: white;
  }
  
}


.dividerContainer {

  width: 100%;
  // background-color: #123321;

  div:nth-of-type(1) {
    background-color: var(--main-color);
  }
  div:nth-of-type(2) {
    background-color: var(--medium-color);
  }
  div:nth-of-type(3) {
    background-color: var(--secondary-color);
  }
  div:nth-of-type(4) {
    background-color: var(--light-color);
  }

}