ul.imageList {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    max-width: 100%;
}

.imageList img{
    object-fit: contain;
    background-color: darkgray;
    padding: 0.5rem;
}

button.avatar {
    width: 300px;
    height: 300px;
    border-radius: 5px;
    border: none;
}

div.imageListItemBar {
    background-color: rgba(0, 0, 0, 0)
}

button.iconButton {
    color: rgba(255, 255, 255, 1);
}
