.formHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .buttonContainer {
    display: flex;
    gap: 1rem;
  }
}

.customerAvatar {
  max-height: 250px;
  width: 250px;

  img{
    object-fit: contain;
  }
}

/* FORMULAR */
.form {
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}